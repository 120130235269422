<template lang="pug">
.main-wrapper.unidades-listar
    
    EstabelecimentosDialog(:especialista="modalLocais.data" :visible="modalLocais.visible" @close='closeDialog()')
    Panel.mb-2( header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid
                .p-col-12.p-md-5
                    label.form-label Especialista/CRM:
                    .p-inputgroup
                        InputText(
                            placeholder='Nome do Especialista'
                            :autoClear='false'
                            @keyup.enter.native='applyFilters()'
                            v-model='filters.nm_especialista'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()' focusable=false)
                .p-col-12.p-md-5
                    label.form-label Estabelecimento:
                    .p-inputgroup
                    Dropdown(
                        v-model='filters.cd_estabelecimentos'
                        :options='optionEstabs'
                        optionLabel='nm_fantasia' 
                        optionValue='id' 
                        dataKey='id' 
                        placeholder='Selecione'
                        @change='applyFilters()'
                        :filter='true'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()' focusable=false
                    )
                .p-col-12.p-md-2
                    label.form-label Medclub Empresa:
                    .p-inputgroup
                    Dropdown(
                        v-model='filters.ie_medclub_empresa'
                        :options='options.ie_atende_medclub_empresa'
                        optionLabel='text' 
                        optionValue='value' 
                        dataKey='value' 
                        placeholder='Selecione'
                        @change='applyFilters()'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()' focusable=false
                    )
                .p-col-12.p-md-5
                    MultiSelectEspecialidades(
                        @lista-especialidades='mudaEspecialidades'
                    )
                .p-col-12.p-md-4
                    label.form-label Área de atuação:
                    .p-inputgroup
                    MultiSelect(
                        v-model='filters.cd_area_atuacao'
                        :options='options.cd_area_atuacao'
                        optionLabel='ds_area_atuacao' 
                        optionValue='id'
                        dataKey='id' 
                        placeholder='Selecione'
                        @change='applyFilters()'
                        :filter='true'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()' focusable=false
                    )
                .p-col-12.p-md-3
                    label.form-label <br>
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()' style='width=600px')
    Paginator.dataview(:rows='paginator.page_size' :first='(paginator.page_number - 1) * paginator.page_size' :totalRecords='paginator.count' @page="onPage($event)")
    ProgressBar(v-if='waiting' mode="indeterminate")
    Panel(v-else header='Lista de Especialistas')
        Paginator.mb-1(:rows='paginator.page_size' :first='(paginator.page_number - 1) * paginator.page_size' :totalRecords='paginator.count' @page="onPage($event)")
        DataTable(:value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder" responsiveLayout='scroll')
            //- Column(headerStyle='width: 5%; text-align: center' bodyStyle='text-align: center' selectionMode="multiple" :styles="{'min-width': '3rem'}")
            Column(field='nome' bodyStyle='text-align: left;' header='Especialista' headerStyle='width: 60%' sortable)
            Column(field='nm_conselho' bodyStyle='text-align: left;' header='Conselho' headerStyle='width: 20%' sortable)
            Column(field='nr_conselho' bodyStyle='text-align: left;' header='Nº Conselho' headerStyle='width: 20%' sortable)
            Column(bodyStyle='text-align: center;' header='Ações' headerStyle='width: 10%')
                template(#body='{data}')
                    Button.p-button-warning(v-tooltip.top='"Locais de Atendimento"' icon='jam jam-building' @click="openDialog(data)")
            
        Paginator(:rows='paginator.page_size' :first='(paginator.page_number - 1) * paginator.page_size' :totalRecords='paginator.count' @page="onPage($event)")  
</template>
    
<style lang="scss">
    .unidades-listar {
        padding: 0 !important;
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
    }
    .p-tooltip {
        max-width: none;
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'
    import TabView from 'primevue/tabview'
    import TabPanel from 'primevue/tabpanel'
    import MultiSelect from 'primevue/multiselect'
    import MultiSelectEspecialidades from '../CustomComponents/MultiSelectEspecialidades.vue';
    import { MedEmpresa, AreaAtuacao } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import EstabelecimentosDialog from './EstabelecimentosDialog'
    import Dropdown from 'primevue/dropdown'


    export default {
        created () { 
            // Estabelecimento.findAllClean().then(response => {
            //     if (([200,201]).includes(response.status)) {
            //         this.optionEstabs = [{nm_fantasia: "-- TODOS --", id: null}, ...response.data]
            //     }
          
            // })
            AreaAtuacao.findAll().then(response => {
                if(([200,201]).includes(response.status)){
                    this.options.cd_area_atuacao = response.data
                }
            })
            this.applyFilters() 
        },
        components: { ProgressBar, DataView, Panel, Paginator, DataTable, Dropdown,
            EstabelecimentosDialog, Column, Button, Tooltip, Dialog, MultiSelectEspecialidades,
            ProgressSpinner, InputText, MedEmpresa, TabPanel,  TabView, MultiSelect },
        directives: { tooltip: Tooltip },
        props: ['optionEstabs'],
        data () {
            return {
                list:[],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                modalLocais: {
                    visible: false,
                    data: null
                },
                // optionEstabs: [],
                options:{
                    ie_atende_medclub_empresa: [
                        { value: null, text: "Todos" },
                        { value: "True", text: "Sim" },
                        { value: "False", text: "Não" }
                    ],
                    cd_area_atuacao: []
                },
                filters: {
                    cd_especialidade: null,
                    cd_estabelecimentos: null,
                    ie_medclub_empresa: "True",
                    cd_area_atuacao: null
                },
                paginator: {
                    page_number: this.$route.query.pg ? this.$route.query.pg : 1,
                    page_size: wsConfigs.paginator_perPage,
                    count: 0
                },
                order: {
					field: '',
					sortOrder: 1
				}
            }
        },
        methods: {
            applyFilters(page) {
                this.paginator.page_number = page || 1
                if (this.$route.query.pg != this.paginator.page_number) this.$router.replace( { query: { pg: this.paginator.page_number } } )

                let params = { paginacao: true, page_number: this.paginator.page_number, page_size: this.paginator.page_size }
                params.order = `${ this.order.sortOrder === 1 ? '' : '-' }${ this.order.field }`

                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key] !== null && this.filters[key] !== "") params[key] = this.filters[key]
                })
                this.getList(params)
            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
            getList (params) {
                this.waiting = true
                return MedEmpresa.finAllEsp(params).then(response => {
                    if (([200,201]).includes(response.status)) {
                        this.paginator.count = response.data.count
                        this.list = []
                        this.selected = []
                        this.list = response.data.results
                    }
                    this.waiting = false
                    return true
                })
            },
            openDialog(data) {
                this.modalLocais.visible = true;
                this.modalLocais.data = data;
            },
            closeDialog(){
                this.modalLocais.visible = false;
                this.modalLocais.data = null;
                this.applyFilters(this.paginator.page_number)
            },
            mudaEspecialidades(dado) {
                this.filters.cd_especialidade = dado
                this.applyFilters()
            },  
            clearFilters() {
                this.filters= {
                    cd_especialidade: null,
                    cd_estabelecimentos: null,
                },
                this.applyFilters()
            }
        }
    }
</script>
    