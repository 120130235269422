<template lang="pug">
.main-wrapper.rc-estabs
    header.main-wrapper-header
        .p-grid.p-align-center
            .p-col-12.ta-right
                h1.text-header.text-secondary MedClub / Empresas / <b>Rede Credenciada</b>

    Dialog.dialogApagar(header='Remover Unidade' :visible.sync='dialogApagar' @hide='closeDialog()' :modal='true')
        p Tem certeza que deseja alterar o status da empresa <b>{{ dialogApagar_data?.nm_fantasia }}</b>?
        .ta-center.my-4
            ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
            Button.p-button-warning(v-else label='Alterar' @click='salvarEstabs(dialogApagar_data)')

    TabView(@tab-change='onTabChange')
        TabPanel(header='Estabelecimentos' v-bind:telaVisivel="'ES'" :active="telaVisivel === 'ES'")
        TabPanel(header='Especialistas' v-bind:telaVisivel="'ESP'" :active="telaVisivel === 'ESP'" @click="telaVisivel='ESP'")
        TabPanel(header='Procedimentos' v-bind:telaVisivel="'PR'" :active="telaVisivel === 'PR'" @click="telaVisivel='PR'")
        Panel.mb-2( v-if="telaVisivel == 'ES'" header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid
                .p-col-12.p-md-4
                    label.form-label Estabelecimento:
                    .p-inputgroup
                        InputText(placeholder='Estabelecimento'
                            @keyup.enter.native='applyFilters()'
                            @input='checkEmpty_nm_fantasia()'
                            v-model='filters.nm_fantasia'
                            v-tooltip.top.focus="'Aperte ENTER para pesquisar'"
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')
                .p-col-12.p-md-3
                    label.form-label Código do procedimento:
                    .p-inputgroup
                        InputText(placeholder='Estabelecimento'
                            @keyup.enter.native='applyFilters()'
                            @input='checkEmpty_cd_procedimento()'
                            v-model='filters.cd_procedimento'
                            v-tooltip.top.focus="'Aperte ENTER para pesquisar'"
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')
                .p-col-12.p-md-2
                    label.form-label Medclub Empresa:
                    .p-inputgroup
                    Dropdown(
                        v-model='filters.ie_atende_medclub_empresa'
                        :options='options.ie_atende_medclub_empresa'
                        optionLabel='text' 
                        optionValue='value' 
                        dataKey='value' 
                        placeholder='Selecione'
                        @change='applyFilters()'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()' focusable=false
                    )
                .p-col-12.p-md-3
                    label <br/>
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()' style='width=500px')
        div(v-if="telaVisivel == 'ES'")
            ProgressBar(v-if='waiting' mode="indeterminate")
            div(v-else-if='list.length == 0')
                p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
            div(v-else)
                DataView.dataview.my-2(:value="list" layout="grid")
                    template(#grid="props")
                        .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                            Panel.panel-list.ta-center(:header="props.data.nm_fantasia" style='position: relative')
                                .ta-left
                                    p <b>Nome Fantasia:</b> {{ props.data.nm_fantasia }}
                //- .ta-right.my-2
                //-     Button.mr-2(label='Redefinir' icon="jam jam-redo" @click="getList({ 'rede_medclub_empresa': true })")
                //-     Button.p-button-success(label='Salvar' icon="jam jam-save" @click="salvarEstabs()")
                Panel.datatable(header="Lista de Estabelecimentos")
                    DataTable.datatable(:value="list" :selection.sync="selected" dataKey="id" :scrollable="true" scrollHeight="400px")
                        //- Column(headerStyle='width: 5%; text-align: center' bodyStyle='text-align: center' selectionMode="multiple" :styles="{'min-width': '3rem'}")
                        Column(headerStyle='width: 10%; text-align: center' header='Status' bodyStyle='text-align: center' :styles="{'min-width': '3rem'}")
                            template(#body='{data}')
                                ProgressSpinner.waitingStatus(v-if='waitingStatus == data.id' strokeWidth='6')
                                InputSwitch(v-else v-model='data.ie_atende_medclub_empresa' @change="clickAlterar(data)")
                        Column(headerStyle='width:40%' field='nm_fantasia' header='Nome Fantasia')
                            template(#body='props')
                                span(v-tooltip.top="props.data.nm_fantasia" style='cursor: pointer;') {{ props.data.nm_fantasia }}

                        Column(headerStyle='width:40%' field='nm_razao_social' header='Razão social')
                            template(#body='props')
                                span(v-tooltip.top="props.data.nm_fantasia" style='cursor: pointer;') {{ props.data.nm_razao_social }}

                        Column(headerStyle='width:15%' field='nr_cnpj' header='CNPJ')
                            template(#body='props')
                                span(v-tooltip.top="props.data.nr_cnpj" style='cursor: pointer;') {{ props.data.nr_cnpj_f }}

    
        especialistas(v-else-if="telaVisivel=='ESP'" :optionEstabs = 'optionEstabs')
        procedimentos(v-else-if="telaVisivel=='PR'" :optionEstabs = 'optionEstabs')
</template>

<style lang="scss">
    .rc-estabs {
        .waitingStatus {
            width: 29px;
            height: auto;
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
        }
    }
	.p-tooltip {
		max-width: none;
	}
</style>    

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Dropdown from 'primevue/dropdown'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'
    import TabView from 'primevue/tabview'
	import TabPanel from 'primevue/tabpanel'
    import InputSwitch from 'primevue/inputswitch'
    import { Estabelecimento, MedEmpresa } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
    import especialistas from "@/components/RedeCredenciada/especialistas";
    import procedimentos from "@/components/RedeCredenciada/procedimentos";


    export default {
        created () { 
            this.getEstabs()
            this.applyFilters() 
        },
        components: { ProgressBar, DataView, Panel, Paginator, DataTable,Dropdown,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, procedimentos,
            MedEmpresa, TabPanel, TabView, especialistas, InputSwitch },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                selected: [],
                waitingStatus: null,
                windowInnerWidth: window.innerWidth,
                optionEstabs: [],
                waiting: false,
                waitingEspecialistas: false,
                waitingApagar: false,
                dialogApagar_data: null,
                dialogApagar: false,
                telaVisivel: 'ES',
                options: {
                    especialidades: [],
                    estabelecimento: [],
                    ie_atende_medclub_empresa: [
                        { value: null, text: "Todos" },
                        { value: true, text: "Sim" },
                        { value: false, text: "Não" }
                    ]
                },
                filters: {
					nm_fantasia: '',
                    nm_especialista: '',
                    ie_atende_medclub_empresa: true,
                    cd_procedimento: null,
                    cd_especialidades_list: [],
                    cd_estabelecimentos_list: [],
				},
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        methods: {
            getListEspecialistas (params) {
                return params
            },
            getList (params) {
                if(this.telaVisivel == "ES"){
                    this.waiting = true
                    return Estabelecimento.findAllClean(params).then(response => {
                        if (([200,201]).includes(response.status)) {
                            this.list = []
                            this.selected = []
                            response.data.forEach(estabelecimento => {
                                if (estabelecimento.nr_cnpj) estabelecimento.nr_cnpj_f = estabelecimento.nr_cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                            })
                            this.list = response.data
                            if(!params) this.options.estabelecimento = response.data
                            this.list.forEach((estab)=>{
                                if(estab.ie_atende_medclub_empresa){
                                    this.selected.push(estab);
                                }
                            });
                            // this.$forceUpdate()
                        }
                        this.waiting = false
                        return true
                    })
                }
            },
            checkEmpty_nm_fantasia () {
                if (! this.filters.nm_fantasia) this.applyFilters()
            },
            checkEmpty_cd_procedimento () {
                if (! this.filters.cd_procedimento) this.applyFilters()
            },
            applyFilters () {
                let params = { 'rede_medclub_empresa': true }
                Object.keys(this.filters).forEach((key) => { if (!([null, undefined, ""]).includes(this.filters[key])) params[key] = this.filters[key]  })
                console.log("params", params)
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            salvarEstabs(data){
                this.waitingApagar = true
                let selected_estabs = [...this.optionEstabs.filter(i => i.id != null && i.ie_atende_medclub_empresa), ...this.selected]
                let dataSend = selected_estabs.map(item => item.id)
                dataSend = dataSend.filter((este, i) => dataSend.indexOf(este) === i)
                if(data.ie_atende_medclub_empresa) dataSend.push(data.id)
                else dataSend = dataSend.filter(item => item != data.id)
                
                dataSend = { "estabelecimentos": dataSend }
                MedEmpresa.credenciamentoEstabelecimento(dataSend).then(response => {
                    this.waitingApagar = false;
                    if (([200,201,204]).includes(response.status)) { 
                        this.getEstabs()
                        this.waitingStatus = null
                        this.dialogApagar = false;
                        this.$toast.success("Alteração realizada com sucesso", { duration: 3000 })
                        // this.getList({ 'rede_medclub_empresa': true }) 
                    }
                    else {
                        this.$toast.error("Falha na alteração do status da empresa", { duration: 3000 })
                    }
                })
            },
            onTabChange (ev) {
				this.telaVisivel = ev.tab.$attrs.telaVisivel
                if(this.telaVisivel == "ES")
                    // this.getList({ 'rede_medclub_empresa': true })
                    this.applyFilters()
			},
            getEstabs(){
                Estabelecimento.findAllClean({ 'rede_medclub_empresa': true }).then(response => {
                if (([200,201]).includes(response.status)) {
                    this.optionEstabs = [{nm_fantasia: "-- TODOS --", id: null}, ...response.data]
                }
            })
            },
            mudaEspecialidades(dado) {
                this.filters.cd_especialidades_list = dado
                this.applyFilters()
            },  
            clickAlterar(data){
                this.waitingStatus = data.id
                this.dialogApagar_data = data
                this.dialogApagar = true
            },
            closeDialog(){
                // this.dialogApagar_data.ie_atende_medclub_empresa = !this.dialogApagar_data.ie_atende_medclub_empresa
                this.waitingStatus = null
                this.dialogApagar_data = null
                this.dialogApagar = false
                this.applyFilters()
            },
            clearFilters() {
                this.filters = {
					nm_fantasia: '',
                    nm_especialista: '',
                    cd_especialidades_list: [],
                    cd_estabelecimentos_list: [],
				}
                this.applyFilters()
            }
        }
    }
</script>
