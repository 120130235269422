<template lang="pug">
Dialog.dialogLocais(:visible.sync='show' :modal='true')
    template(#header) Locais de Atendimento de <u> {{ especialista.nome }} </u>
    ProgressBar.progressLoading(v-if='waitingSubmit' mode="indeterminate")
    DataTable.datatable(v-else :value="list" @sort="onSort($event)" responsiveLayout="scroll" :expandedRows.sync="expandedRows" @row-expand="onRowExpand" @row-collapse="onRowCollapse" :sortField="order.field" :sortOrder="order.sortOrder" )
        template(#empty)
            p.ta-center.text-secondary Nenhum estabelecimento encontrado.
        template
            Column(:expander="true" headerStyle="width: 10%")
            Column(header='Estabelecimento' headerStyle='width: 50%')
                template(#body='{data}')
                    span(v-tooltip.top='data.nm_razao_social') {{ data.nm_fantasia }}
            Column(header='Atende Medclub Empresa' bodyStyle='text-align: center;' field='ie_atende_medclub_empresa' headerStyle='width: 20%')
                template(#body='{data, index}')
                    InputSwitch(v-model='data.ie_atende_medclub_empresa' @change='onChangeEmpresa(index)')
        template(#expansion='props')
            div(class="orders-subtable")
                DataTable(:value="props.data.agenda_especialidade")
                    Column(header='Especialidade' field='cd_especialidade__nm_especialidade' headerStyle='width: 70%')
                    Column(header='Atende Medclub Empresa' bodyStyle='text-align: center;' field='ie_atende_medclub_empresa' headerStyle='width: 30%')
                        template(#body='propss')
                            InputSwitch(v-model='propss.data.ie_atende_medclub_empresa' :disabled='!props.data.ie_atende_medclub_empresa')
    Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")  
    .ta-right(style="margin-right:20px; margin-top:20px")                       
        Button.addButton(label='Salvar' :loading="waitingSubmit" type='button' icon='jam jam-save' @click='onSubmit()')                        
    
</template>
    
<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Panel from 'primevue/panel'
import InputText from 'primevue/inputtext'
import Paginator from 'primevue/paginator'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import SelectButton from 'primevue/selectbutton'
import ProgressBar from 'primevue/progressbar'
import Tooltip from 'primevue/tooltip'
import { MedEmpresa } from './../../middleware'
import InputSwitch from 'primevue/inputswitch'

import * as _ from 'lodash'

export default {
    mounted() {  },
    components: { Button, Dialog, Panel, InputSwitch, InputText, Paginator, ProgressBar, DataTable, Column, SelectButton },
    directives: { tooltip: Tooltip },
    props: [ 'especialista', 'visible'],
    computed: {
        show: {
            get() { return this.visible; },
            set(val) { if (!val) this.$emit('close'); }
        }
    },
    watch: {
        "especialista": function(val) {
            if(val) this.list = val.instancias;
        }
    },
    data () {
        return {
            list: [],
            expandItem: null,
            expandedRows: [],
            alteredItems: [],
            waitingSubmit: false,
            waiting: false,
            windowInnerWidth: window.innerWidth,
            header: '',
            filters: {
                nm_especialista: '',
                cd_especialidades_list: [],
                cd_estabelecimentos_list: [],
            },
            paginator: {
                page: this.$route.query.pg || 1,
                per_page: 5,
                count: 0
            },
            order: {
                field: 'nm_fantasia',
                sortOrder: 1
            },
            getListDebounce: _.debounce(function (params, getFunction) {
                getFunction(params)
            }, 500, { leading: true }),
        }
    },
    methods: {
        onPage (ev) {
            this.applyFilters(ev.page + 1)
        },
        onSort(ev) {
            this.order.field = ev.sortField
            this.order.sortOrder = ev.sortOrder
            this.applyFilters()
        },
        
        onRowExpand(event) {
            this.expandItem = event.data
        },
        onRowCollapse() {
            this.expandItem = null;
        },
        onSubmit() {
            this.waitingSubmit = true;
            let dataSend = Object.assign([], this.especialista.instancias)
            dataSend.forEach(item => {
                item.id = item.id_especialista
            })
            MedEmpresa.saveEsp(dataSend).then(response => {
                this.waitingSubmit = false;
                if(([200,201]).includes(response.status)){
                    this.$toast.success("Médico atualizado com sucesso", { duration: 3000 })
                    this.$parent.closeDialog(this.paginator.page)
                }
                else{
                    this.$toast.error("Erro ao salvar dados", { duration: 3000 })
                }
            })
        },
        onChangeEmpresa(index) {
            if(!this.especialista.instancias[index].ie_atende_medclub_empresa){
                this.especialista.instancias[index].agenda_especialidade.forEach(item => {
                    item.ie_atende_medclub_empresa = false
                })
            }
        }
    }
}
</script>

<style lang="scss">
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }
        .cell {
            padding: 16px 0;
            text-align: center;
            &.ex  {  background-color: #e4f8e1; }
            &.a  { background-color: #faf3dd; }
        }
    }
    .progressLoading{
        min-width: 1000px;
    }
    .dialogLocais {
        max-width: 60%;
    }
    .div-spinner {
        text-align: center
    }
    .text-secondary {
        margin: 20px 0;
    }
    .selectButton {
        i {
            font-size: x-large;
        }
    }
</style>